<template lang="pug">
.video-card
  .content
    h3.title
      | {{ title }}
    slot
  .btn-play(@click="showVideo = true", :class="{ hidden: !hasVideoUrl }")
    md-button.md-icon-button.md-raised.md-primary
      md-icon play_arrow
  md-dialog.video(
    :md-active.sync="showVideo",
    :md-fullscreen="false",
    v-on:md-opened="initPlayer",
    v-on:md-closed="cleanup"
  )
    video.player.video-js(ref="player", preload="none", controls)
</template>

<script>
export default {
  name: "video-card",
  data() {
    return {
      video_play_tracking_timer: null,
      player: null,
      showVideo: false,
    };
  },
  props: {
    title: String,
    videoUrl: String,
    videoType: { type: String, default: "video/mp4" },
  },
  computed: {
    hasVideoUrl() {
      return this.videoUrl !== undefined && this.videoUrl !== "";
    },
  },
  methods: {
    initPlayer() {
      this.$pushEvent("video", { video: this.title });
      this.player = this.$video(this.$refs.player, {
        controls: true,
        autoplay: true,
        preload: "auto",
        fluid: true,
        sources: [
          {
            src: this.videoUrl,
            type: this.videoType,
          },
        ],
      });

      if (window.WeixinJSBridge) {
        WeixinJSBridge.invoke(
          "getNetworkType",
          {},
          () => {
            this.player.play();
          },
          false
        );
      } else {
        document.addEventListener(
          "WeixinJSBridgeReady",
          () => {
            WeixinJSBridge.invoke("getNetworkType", {}, () => {
              this.player.play();
            });
          },
          false
        );
      }
      this.player.play();

      this.video_play_tracking_timer = setInterval(() => {
        this.$pushEvent("video_play", {
          video: this.title,
          time: this.player.currentTime(),
        });
      }, 10000);
    },
    cleanup() {
      clearInterval(this.video_play_tracking_timer);
    },
  },
};
</script>

<style scoped lang="stylus">
.video-card {
  display: flex;
  margin: 16px 5px 16px 20px;
  padding: 10px 5px;

  .hidden {
    visibility: hidden;
  }

  @media screen and (min-width: 1280px) {
    margin: 16px 50px 16px 50px;
    padding: 10px 5px 10px 80px;
  }

  h2 {
    text-align: left;
    color: #000;
  }

  h3 {
    text-align: left;
    color: #000;
  }

  .title {
    font-weight: normal;
    font-size: 20px;
    margin: 5px 0 30px 0;
    line-height: normal;
  }

  .name {
    margin: 0;
  }

  .content {
    flex: 1;

    .content-details {
      @media screen and (min-width: 1280px) {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      .speaker {
        display: flex;
        flex: 1;
        padding: 20px 0;

        @media screen and (min-width: 1280px) {
          padding: 0;
        }

        .avatar {
          width: 64px;
          height: 64px;
          border-radius: 32px;
        }

        .details {
          flex: 1;
          margin: 0 20px;
          color: #858585;
        }
      }
    }
  }

  .btn-play {
    width: 64px;
  }
}

.video-card:nth-child(even) {
  background: #ffffff;
}

.video-card:nth-child(odd) {
  background: #f8f9fb;
}
</style>

<style lang="stylus">
.md-dialog {
  .md-dialog-container {
    min-width: 90%;
    max-width: 100%;
    max-height: 100%;

    @media screen and (min-width: 768px) {
      min-width: 80%;
    }
  }
}
</style>
