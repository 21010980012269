<template lang="pug">
#root
  md-app.app(:md-scrollbar="false")
    md-app-toolbar.flex.bg-white.md-toolbar
      md-button.md-icon-button.mobile(@click="menuVisible = !menuVisible")
        md-icon menu
      img.header-logo(src="@/assets/images/logo-google.png")
      md-tabs.tabs(md-sync-route)
        md-tab(md-label="谷歌开发者大会", to="/IOConnect")
      .spacer
    md-app-drawer(:md-active.sync="menuVisible")
      md-toolbar.md-transparent(md-elevation="0")
        span.md-title
          | 导航
      md-list
        md-list-item(to="/IOConnect", exact)
          span.md-list-item-text
            | 谷歌开发者大会
    md-app-content.app-container
      .container.cover-container
        img.cover(src="@/assets/images/cover-ioconnect-2023-cover.jpg")
        img.cover-mobile(
          src="@/assets/images/cover-ioconnect-2023-mobile.jpg"
        )
        p.cover-text
          | 2023 Google 开发者大会为开发人员和技术专家提供现场演讲和实践学习，帮助大家深入了解 Google 产品、服务和应用。立刻探索 Google 如何助力开发提效，释放团队创造力，轻松简化工作流程。
      .fixed-container
        h2#videos.header-text(ref="videos")
          | 主旨演讲
          .header-split
        .videos-container
          .videos
            video-card(
              title="2023 Google 开发者大会主旨演讲",
              videoUrl="https://cdn.google.36kr.com/videos/ioconnect/2023Google开发者大会主旨演讲.mp4"
            )
              .content-details
                .speaker
                  img.avatar(
                    src="@/assets/images/ioconnect2023/陈俊廷.png"
                  )
                  .details
                    h3.name
                      | 陈俊廷
                    p
                      | Google 大中华区总裁
                .speaker
                  img.avatar(
                    src="@/assets/images/ioconnect2023/Jeanine Banks.png"
                  )
                  .details
                    h3.name
                      | Jeanine Banks
                    p
                      | Developer X & 开发者关系，副总裁兼总经理
                .speaker
                  img.avatar(
                    src="@/assets/images/ioconnect2023/jingyu.png"
                  )
                  .details
                    h3.name
                      | 史婧羽
                    p
                      | Android CoreOS 技术推广部主管，Google
              .content-details
                .speaker
                  img.avatar(
                    src="@/assets/images/ioconnect2023/paul kinlan.png"
                  )
                  .details
                    h3.name
                      | Paul Kinlan
                    p
                      | 资深开发技术推广工程师兼 Chrome 开发技术推广部主管，Google
                .speaker
                  img.avatar(
                    src="@/assets/images/ioconnect2023/Jason Mayes.png"
                  )
                  .details
                    h3.name
                      | Jason Mayes
                    p
                      | Web 机器学习主管，Google
                .speaker
                  img.avatar(
                    src="@/assets/images/ioconnect2023/ting-liu.png"
                  )
                  .details
                    h3.name
                      | Ting Liu
                    p
                      | Google Cloud AI 平台副总裁
              .content-details
                .speaker
                  img.avatar(
                    src="@/assets/images/ioconnect2023/David McLaughlin.png"
                  )
                  .details
                    h3.name
                      | David McLaughlin
                    p
                      | 全球开发者生态总监
        h2#videos.header-text(ref="videos")
          | 专题演讲
          .header-split
        .videos-container
          .videos
            video-card(
              title="Android 开发工具的新动态",
              videoUrl="https://cdn.google.36kr.com/videos/ioconnect/IO23_030.mp4"
            )
              .content-details
                .speaker
                  img.avatar(
                    src="@/assets/images/ioconnect2023/Jamal Eason.png"
                  )
                  .details
                    h3.name
                      | Jamal Eason
                    p
                      | 产品管理总监
                .speaker
                  img.avatar(
                    src="@/assets/images/ioconnect2023/Tor Norbye.png"
                  )
                  .details
                    h3.name
                      | Tor Norbye
                    p
                      | 工程总监
            video-card(
              title="构建适用于 Web 的通信和联络中心应用",
              videoUrl="https://cdn.google.36kr.com/videos/ioconnect/IO23_043.mp4"
            )
              .content-details
                .speaker
                  img.avatar(
                    src="@/assets/images/ioconnect2023/Raluca Monet.png"
                  )
                  .details
                    h3.name
                      | Raluca Monet
                    p
                      | Chrome OS 全球合作关系主管
                .speaker
                  img.avatar(
                    src="@/assets/images/ioconnect2023/Austin David Christopher.png"
                  )
                  .details
                    h3.name
                      | Austin David Christopher
                    p
                      | Chrome 企业合作伙伴工程师
            video-card(
              title="使用 MediaPipe 轻松实现设备端机器学习",
              videoUrl="https://cdn.google.36kr.com/videos/ioconnect/IO23_104.mp4"
            )
              .content-details
                .speaker
                  img.avatar(
                    src="@/assets/images/ioconnect2023/Khanh LeViet.png"
                  )
                  .details
                    h3.name
                      | Khanh LeViet
                    p
                      | 技术推广工程师
            video-card(
              title="移动开发技术前沿分享",
              videoUrl="https://cdn.google.36kr.com/videos/ioconnect/移动开发技术前沿分享.mp4"
            )
              .content-details
                .speaker
                  img.avatar(
                    src="@/assets/images/ioconnect2023/jingyu.png"
                  )
                  .details
                    h3.name
                      | 史婧羽
                    p
                      | Android CoreOS 技术推广部主管，Google
                .speaker
                  img.avatar(
                    src="@/assets/images/ioconnect2023/高Hanrui.png"
                  )
                  .details
                    h3.name
                      | 高寒蕊
                    p
                      | 开发者关系工程师，Google
            video-card(
              title="与 Google Cloud 一起踏上云端新旅程",
              videoUrl="https://cdn.google.36kr.com/videos/ioconnect/与GoogleCloud一起踏上云端新旅程.mp4"
            )
              .content-details
                .speaker
                  img.avatar(
                    src="@/assets/images/ioconnect2023/bhall.png"
                  )
                  .details
                    h3.name
                      | Brian Hall
                    p
                      | Google Cloud 产品营销副总裁
            video-card(
              title="深度解析机器学习产品新动态",
              videoUrl="https://cdn.google.36kr.com/videos/ioconnect/AI01.mp4"
            )
              .content-details
                .speaker
                  img.avatar(src="@/assets/images/ioconnect2023/κΡ.png")
                  .details
                    h3.name
                      | 魏巍
                    p
                      | 技术推广工程师，Google
            video-card(
              title="Web 平台新动向",
              videoUrl="https://cdn.google.36kr.com/videos/ioconnect/WE01.mp4"
            )
              .content-details
                .speaker
                  img.avatar(
                    src="@/assets/images/ioconnect2023/paul kinlan.png"
                  )
                  .details
                    h3.name
                      | Paul Kinlan
                    p
                      | 资深开发技术推广工程师兼 Chrome 开发技术推广部主管，Google
            video-card(
              title="谷歌女性领袖圆桌论坛和多元、平等、共融案例分享",
              videoUrl="https://cdn.google.36kr.com/videos/ioconnect/WTM.mp4"
            )
              .content-details
                .speaker
                  img.avatar(
                    src="@/assets/images/ioconnect2023/Sara Jen.png"
                  )
                  .details
                    h3.name
                      | 任宜伦
                    p
                      | 谷歌中国区品牌和开发者市场部副总经理
                .speaker
                  img.avatar(
                    src="@/assets/images/ioconnect2023/PurnimaKochikar.png"
                  )
                  .details
                    h3.name
                      | Purnima Kochikar
                    p
                      | Google Play 合作伙伴关系，副总裁
                .speaker
                  img.avatar(
                    src="@/assets/images/ioconnect2023/Jeanine Banks.png"
                  )
                  .details
                    h3.name
                      | Jeanine Banks
                    p
                      | Developer X & 开发者关系，副总裁兼总经理
              .content-details
                .speaker
                  img.avatar(
                    src="@/assets/images/ioconnect2023/Kay Aoki.png"
                  )
                  .details
                    h3.name
                      | Kay Aoki
                    p
                      | Cloud 日本和亚太地区高级营销总监
                .speaker
                  img.avatar(
                    src="@/assets/images/ioconnect2023/Maggie Wei.png"
                  )
                  .details
                    h3.name
                      | 魏晓倩
                    p
                      | Google 上海研发中心总经理
                .speaker
                  img.avatar(
                    src="@/assets/images/ioconnect2023/Peng Liu.png"
                  )
                  .details
                    h3.name
                      | 刘鹏
                    p
                      | 中国发展研究基金会儿童发展研究院项目主任
              .content-details
                .speaker
                  img.avatar(
                    src="@/assets/images/ioconnect2023/李润霞.png"
                  )
                  .details
                    h3.name
                      | 李润霞
                    p
                      | 华池县五蛟镇督导员
            video-card(
              title="谷歌 AI 的创新案例分享",
              videoUrl="https://cdn.google.36kr.com/videos/ioconnect/PI01.mp4"
            )
              .content-details
                .speaker
                  img.avatar(
                    src="@/assets/images/ioconnect2023/Jay ji.png"
                  )
                  .details
                    h3.name
                      | 吉程
                    p
                      | 产品经理
                .speaker
                  img.avatar(
                    src="@/assets/images/ioconnect2023/SisiJin.png"
                  )
                  .details
                    h3.name
                      | 靳思颖
                    p
                      | 用户体验设计师
      .fixed-container
        h2.header-text
          | 大会精彩内容
          .header-split
      #articles.container
        carousel(:perPage="1", :autoplay="false", :loop="true")
          slide
            .slide-container
              .article-card(
                @click="openUri('https://mp.weixin.qq.com/s/E6kknbMFnkQuw_amaZ_QXw')"
              )
                img.article-image(src="@/assets/images/ioconnect2023/article1.jpeg")
                .article-content
                  p.article-content-title
                    | 文章
                  h3
                    | 多元共进｜2023 Google 谷歌开发者大会主旨演讲亮点回顾
              .article-card(
                @click="openUri('https://mp.weixin.qq.com/s/rjwbaV3qMp3TFmcGlEO81w')"
              )
                img.article-image(src="@/assets/images/ioconnect2023/精彩回顾.png")
                .article-content
                  p.article-content-title
                    | 文章
                  h3
                    | 多元共进｜2023 Google 谷歌开发者大会现场全回顾
      .footer
        img.footer-logo(src="@/assets/images/logo-google-grey.png")
  .hidden(
    :class="{ 'share-mobile': isShare }",
    @click="() => { isShare = false; }"
  )
    img.share-img(src="~@/assets/images/share.png")
  .shares
    .share-icon-container(@click="share")
      md-icon.share-icon share
      .hidden(
        :class="{ 'share-pc': isShare }",
        @click="() => { isShare = false; }"
      )
        img(src="~@/assets/images/ioconnect2023/share.png", width="150px")
    .share-icon-container(@click="like")
      md-icon.share-icon(:class="{ active: isActive }") favorite
</template>

<script>
import VideoCard from "../components/video-card.vue";

export default {
  name: "IOConnect",
  components: { VideoCard },
  data() {
    return {
      isActive: false,
      isStar: false,
      isShare: false,
      isVideoToEnd: false,
      menuVisible: false,
    };
  },
  methods: {
    like() {
      this.$pushEvent("like", {});
      this.isActive = !this.isActive;
    },
    star() {
      this.isStar = !this.isStar;
    },
    share() {
      this.$pushEvent("share", {});
      this.isShare = !this.isShare;
    },
    openUri(uri) {
      this.$pushEvent("openUri", { url: uri }).then(() => {
        window.location.assign(uri);
        // const ua = navigator.userAgent.toLowerCase();
        // if (
        //   (ua.indexOf("safari") !== -1 && ua.indexOf("chrome") === -1) ||
        //   ua.indexOf("micromessenger") !== -1
        // ) {
        //   window.location.assign(uri);
        // } else {
        //   window.open(uri, "_blank");
        // }
      });
    },
  },
  mounted() {
    this.$pushEvent("visit", {
      route: "ioconnect",
      source: window.location.search,
    });

    // This is a hack to Chromium bug
    if (window.location.hash.length > 0) {
      const hash = window.location.hash.substring(1);
      document.getElementById(hash).scrollIntoView();
      // hack to Safari bug
      const ua = navigator.userAgent.toLowerCase();
      if (
        (ua.indexOf("safari") !== -1 && ua.indexOf("chrome") === -1) ||
        ua.indexOf("micromessenger") !== -1
      ) {
        window.setTimeout(() => {
          document.getElementById(hash).scrollIntoView();
        }, 100);
      }
    }
  },
};
</script>

<style lang="stylus" scoped>
img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

h2 {
  text-align: center;
}

.header-split {
  width: 100%;
  height: 3px;
  margin-top: 8px;
  background: #448aff;
}

h3 {
  font-size: 15px;
}

a {
  cursor: pointer;
}

.report-h2 {
  font-size: 19px;
}

.tabs {
  @media screen and (min-width: 1280px) {
    display: block;
  }

  display: none;
}

.md-toolbar {
  min-height: 48px;
}

.md-toolbar .md-tabs {
  margin: 0 !important;
}

.pc {
  @media screen and (min-width: 1280px) {
    display: block;
  }

  display: none;
}

.mobile {
  @media screen and (min-width: 1280px) {
    display: none;
  }

  display: block;
}

.arrow-container {
  @media screen and (min-width: 1280px) {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 50px;
  }

  display: none;
}

.arrow {
  @media screen and (min-width: 1280px) {
    top: 10px;
    z-index: 9;
    display: block;
    cursor: pointer;
  }
}

.arrow-back {
  margin-right: 10px;
}

.arrow-forward {
  margin-left: 10px;
}

.arrow-icon {
  background-color: #cccccc;
  width: 10px;
  height: 10px;
  border-radius: 5px;

  &.active {
    background-color: #000000;
  }
}

.share-img {
  width: 200px;
  position: absolute;
  right: 0;
  top: 0;
}

.hidden {
  display: none;
}

.show-player {
  display: flex;
}

.share-pc {
  @media screen and (min-width: 1024px) {
    display: block;
    position: absolute;
    left: -150px;
    top: -90px;
  }
}

.share-mobile {
  @media screen and (max-width: 1024px) {
    display: block;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.3;
  }
}

.shares {
  position: fixed;
  background-color: #fff;
  right: 0;
  top: 50%;
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-radius: 10px 0 0 10px;

  @media screen and (max-width: 1280px) {
    top: 30%;
  }
}

.share-icon-container {
  margin: 10px;
  cursor: pointer;
}

.share-icon {
  color: #000 !important;
}

.close-icon {
  margin: 0 0 0 auto;
  cursor: pointer;
}

.active {
  color: #ff0000 !important;
}

.star {
  color: #ffcc00 !important;
}

.my-4 {
  margin-bottom: 20px;
}

.my-8 {
  margin-bottom: 40px;
}

.app {
  width: 100%;
  max-height: 100vh;
  position: absolute;
}

.app-container {
  max-width: 100vw;
  max-height: 100vh;
}

.flex {
  display: flex;
}

.spacer {
  flex: 1;
}

.header-logo {
  height: 24px;
}

.padding-btn {
  padding: 5px 10px;
}

.container {
  width: 100%;
  margin: 0 auto;
}

.fixed-container {
  max-width: 1200px;
  margin: 0 auto;
}

.cover-container {
  padding: 10px 0 50px 0;

  @media screen and (min-width: 1280px) {
    padding: 50px 200px;
  }

  background: #fff;
}

.cover {
  display: none;
  width: 100vw;
  margin: auto;

  @media screen and (min-width: 1280px) {
    display: block;
  }
}

.cover-text {
  padding: 0 20px;
  line-height: 1.8;
  text-align: left;

  @media screen and (min-width: 1280px) {
    padding: 0 50px;
    font-size: 20px;
  }
}

.header-text {
  padding: 16px 20px;
  line-height: 1.8;
  text-align: left;

  @media screen and (min-width: 1280px) {
    padding: 16px 50px;
    font-size: 20px;
  }

  margin: 0;
}

.cover-mobile {
  display: block;
  margin: 10px auto 60px auto;

  @media screen and (min-width: 1280px) {
    display: none;
  }
}

.md-content {
  padding: 0;
}

.article-content-title {
  color: rgb(117, 117, 117);
}

.bg-grey {
  background-color: #f2f2f2;
}

.bg-white {
  background-color: #fff;
}

.md-card-media img {
  border-radius: 32px 32px 0 0;
}

.span-btn:hover {
  text-decoration: none;
}

.span-text {
  display: inline-block;
  line-height: 24px;
  vertical-align: middle;
}

.slide-container {
  @media screen and (min-width: 1280px) {
    width: 800px;
  }

  width: 90%;
  margin: 0 auto;
}

.article-card {
  display: flex;
  cursor: pointer;
  margin: 20px 0;
}

.article-image {
  @media screen and (min-width: 1024px) {
    width: 200px;
    height: calc(200px / 4 * 3);
    border-radius: 32px;
    margin: 0 30px 0 0;
  }

  width: 140px;
  height: calc(140px / 4 * 3);
  border-radius: 16px;
  margin: 0 15px 0 0;
}

.article-content {
  flex: 4;
}

.flex-section {
  display: flex;
  padding: 40px 0;

  @media screen and (min-width: 1280px) {
    flex-direction: row;
    padding: 60px 0;
    justify-content: center;
    align-items: center;
    max-width: 1000px;
    margin: 0 auto;
  }

  flex-direction: column;
}

.section-description {
  margin: 0 20px 10px 20px;
}

.section-image {
  max-width: 90%;

  @media screen and (min-width: 1280px) {
    max-width: 50%;
  }

  width: auto;
  height: auto;
  justify-self: flex-start;
  align-self: flex-start;
  margin: 0 auto;
}

.section-content {
  @media screen and (min-width: 1280px) {
    width: 480px;
    padding: 0 40px;
  }

  padding: 0 10px;
  margin: auto 0;
}

.lg-btn {
  width: 220px;
  margin: 20px auto;
  display: block;
}

.flex-reverse {
  @media screen and (min-width: 1280px) {
    flex-direction: row-reverse;
  }

  flex-direction: column;
}

.footer {
  background-color: #f5f5f5;
  padding: 10px;

  .footer-logo {
    margin: 0 auto 0 auto;
    height: 40px;
    display: block;
  }
}

h3.title {
  line-height: 1.4;
}
</style>
